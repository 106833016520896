import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Content, { HTMLContent } from "../components/Content"

export const CleanPageTemplate = ({ title, content, contentComponent }) => {
	const PageContent = contentComponent || Content

	return (
		<section className="section">
			<div className="container">
				<div className="columns">
					<div className="column">
						<PageContent className="content" content={content} />
					</div>
				</div>
			</div>
		</section>
	)
}

CleanPageTemplate.propTypes = {
	title: PropTypes.string.isRequired,
	content: PropTypes.string,
	contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
	const { markdownRemark: post } = data

	return (
		<Layout>
			<CleanPageTemplate
				contentComponent={HTMLContent}
				title={post.frontmatter.title}
				content={post.html}
			/>
		</Layout>
	)
}

AboutPage.propTypes = {
	data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
	query AboutPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			html
			frontmatter {
				title
			}
		}
	}
`
